<template>
  <div>
    <w-top-bar>Orden de Productos</w-top-bar>
    <div class="h-full p-5 bg-gray-200">
      <!-- <div class="flex items-center justify-between h-12 px-1">
        <div class="flex items-center space-x-2 text-xs">
          <w-select
            label="Tipo de Producto"
            empty-label="Seleccionar Categoría"
            class="w-56"
            v-model="productType"
            :options="categories"
            :no-select="true"
            :allow-null="true"
          />
        </div>
      </div> -->

      <!-- Cuerpo de la tabla (productos ordenables) -->
      <draggable
        v-bind="dragOptions"
        @start="drag = true"
        @end="drag = false"
        v-model="products"
        @change="change"
        tag="div"
        class="bg-white divide-y divide-gray-300"
        handle=".handle"
      >
        <!-- Fila para cada producto -->
        <div
          class="bg-white py-2"
          v-for="(product, productIndex) in products"
          :key="productIndex"
        >
          <!-- Celda con el título, imagen y otros detalles del producto -->
          <div class="flex items-center">
            <!-- Botón para mover el producto -->
            <svg
              v-if="productIndex === 'busy'"
              class="w-3 h-3 text-gray-500 animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-50"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-100"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            <button
              v-if="productIndex !== editandoSort"
              v-tooltip="
                'Click y arrastrar para mover el producto, doble click para editar el orden'
              "
              @dblclick="editarSort(productIndex)"
              class="handle p-1 m-2 border flex align-center rounded text-xs text-left hover:bg-indigo-200 text-gray-700 hover:text-gray-900"
            >
              <span class="me-2 text-sm">{{ product.sort + 1 }}</span>
              <svg
                class="w-5 h-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M8 9l4-4 4 4m0 6l-4 4-4-4"
                />
              </svg>
            </button>
            <div v-else>
              <input
                :value="sortAEditar"
                @keyup.enter="
                  guardarNuevoOrden(productIndex, $event.target.value - 1)
                "
                class="w-10 p-1 m-2 border rounded text-xs text-left hover:bg-indigo-200 text-gray-700 hover:text-gray-900"
              />
            </div>

            <!-- Información del producto (título, imagen, etc.) -->
            <div
              class="flex items-center w-56 text-sm font-medium leading-5 text-gray-900 truncate"
            >
              <img class="w-10 h-10 pr-2 rounded" :src="product.image" />
              <div class="">
                <div>{{ product.name }}</div>
                <div class="text-xs font-normal">{{ product.sku }}</div>
              </div>
            </div>
          </div>
        </div>
      </draggable>

      <!-- <transition
        leave-active-class="transition-all duration-300 ease-in-out"
        enter-active-class="transition-all duration-200 ease-in-out"
        enter-class="opacity-0 "
        enter-to-class="opacity-100 "
        leave-to-class="opacity-100 "
        leave-class="opacity-0 "
      >
        <w-products
          v-if="!isFindProductsPending"
          :items="products"
          @update="update()"
        />
        <w-loading v-else />
      </transition> -->
    </div>
  </div>
</template>
  
  <script>
import { mapActions } from "vuex";
import draggable from "vuedraggable";
export default {
  name: "ordenProductos",
  components: {
    draggable,
  },
  data() {
    return {
      editandoSort: -1,
      drag: false,
      dragOptions: {
        animation: 200,
        ghostClass: "ghost",
      },
      products: [],
      categories: [],
      productType: null,
    };
  },
  async created() {
    await this.loadProducts();

    let resCat = await this.findCategories({
      query: {
        $limit: 10000,
        $sort: { sort: 1 },
      },
    });

    this.categories = resCat.data.map((category) => {
      return {
        label: category.name,
        value: category.key,
      };
    });
    // Actualizar el orden de los productos en la base de datos con el orden actual y this.nuevoOrden
    // Buscar si hay dos prod. con el mismo sort
    // Regenerar todo si hay
    const hayDuplicados = this.products.some((product, index) => {
      return this.products.some((product2, index2) => {
        return product.sort === product2.sort && index !== index2;
      });
    });

    // Verificar que no haya sort salteados
    const haySaltos = this.products.some((product, index) => {
      return product.sort !== index;
    });

    if (hayDuplicados || haySaltos) {
      this.$toast.error(
        "Hay productos con el mismo orden, se regeneró el orden de todos los productos"
      );
      this.products.forEach((product, index) => {
        product.sort = index;
      });
      this.nuevoOrden({
        nuevosOrdenes: this.products.map((product, index) => {
          return {
            id: product._id,
            sort: index,
          };
        }),
      });
    }
  },
  watch: {
    productType() {
      this.loadProducts();
    },
  },
  methods: {
    ...mapActions("products", { find: "find" }),
    ...mapActions("categories", { findCategories: "find" }),
    ...mapActions("products-sort", {
      nuevoOrden: "create",
    }),
    async loadProducts() {
      let extra = {};
      console.log("this.productType", this.productType);
      if (this.productType && this.productType.length > 0) {
        extra = {
          types: { $in: [this.productType] },
        };
      }

      let res = await this.find({
        query: {
          $limit: 10000,
          ...extra,
          isPublic: true,
          stock: { $gt: 0 },
          $sort: { sort: 1 },
        },
      });
      this.products = res.data;
    },
    editarSort(index) {
      this.editandoSort = index;
      this.sortAEditar = this.products[index].sort + 1;
    },
    async change(event) {
      if (event.moved) {
        const movedProduct = event.moved.element;
        const newIndex = parseInt(event.moved.newIndex);

        // Actualizar el orden del producto movido
        movedProduct.sort = parseInt(newIndex);

        // Llamar a la acción en Vuex para actualizar el orden en la base de datos
        // await this.patchProduct([movedProduct._id, { sort: newIndex }]);
        // Actualizar el orden de todos los productos que quedaron por debajo del producto movido
        const resultado = await this.nuevoOrden({
          category: this.productType,
          moved: {
            id: movedProduct._id,
            sort: newIndex,
          },
          // Array de objetos con el id y el nuevo orden de cada producto
          nuevosOrdenes: this.products.slice(newIndex).map((product, index) => {
            return {
              id: product._id,
              sort: parseInt(newIndex) + index,
            };
          }),
        });

        if (event.update) {
          console.log("UPDATE");

          const toMove = this.products.slice(newIndex + 1);
          const moved = toMove.splice(0, 1)[0];
          toMove.splice(event.moved.newIndex - newIndex, 0, moved);
          this.products = [
            ...this.products.slice(0, newIndex),
            ...toMove,
            ...this.products.slice(newIndex + 1),
          ];
        }

        // Actualizar la prop sort de cada producto
        this.products.forEach((product, index) => {
          product.sort = index;
        });

        this.$toast.success(
          `Se actualizó el orden de los productos, y afecto a ${resultado.total} productos`
        );
      }
    },
  },
};
</script>
  
  <style lang="scss" scoped></style>
  